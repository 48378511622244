import { onMounted, ref, Ref } from "@vue/composition-api";
import { debounce } from "lodash";

export const useDynamicTree = ({
  handleLoad,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleLoad: (element: any) => void;
}): {
  isLoadingOpenedItems: Ref<boolean>;
  openedItems: Ref<{ id: number | string }[]>;
} => {
  const isLoadingOpenedItems = ref<boolean>(false);
  const openedItems = ref<{ id: number | string }[]>([]);

  const handleScroll = debounce((e) => {
    if (e.target.scrollHeight === e.target.clientHeight + e.target.scrollTop && !isLoadingOpenedItems.value) {
      try {
        isLoadingOpenedItems.value = true;
        const findedElementId = e.target?.parentNode?.getElementsByClassName("tree-node")?.[0]?.id;
        const findedElement = openedItems.value.find((item: { id: number | string }) => item.id == findedElementId);
        if (!findedElement) {
          return;
        }
        handleLoad(findedElement);
      } catch (e) {
        console.log(e);
      } finally {
        isLoadingOpenedItems.value = false;
      }
    }
  }, 500);

  onMounted(() => {
    const observer = new MutationObserver((mutations_list) => {
      mutations_list.forEach((mutation) => {
        (mutation.addedNodes as unknown as HTMLElement[]).forEach((added_node) => {
          if (added_node?.classList?.contains("v-treeview-node__children")) {
            added_node.addEventListener("scroll", handleScroll);
          }
        });
      });
    });
    observer.observe(document.getElementById("case-tree") as Node, { subtree: true, childList: true });
  });

  return {
    isLoadingOpenedItems,
    openedItems,
  };
};
