import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryView/fields";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Архивный шифр",
    tooltip: "Архивный шифр дела",
    className: "info-modal__element_title",
    value: fields.CASE_ARCHIVAL_CIPHER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Порядковый номер",
    tooltip: "Порядковый номер в описи дел",
    className: "info-modal__element_title",
    value: fields.CASE_SERIAL_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Индекс",
    tooltip: "Индекс дела",
    className: "info-modal__element_title",
    value: fields.CASE_INDEX,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок",
    tooltip: "Заголовок дела",
    className: "info-modal__element_title",
    value: fields.CASE_HEADER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОИК",
    tooltip: "Краткое наименование ОИК",
    isLink: true,
    linkRights: [authorities.OIK_LIST],
    className: "info-modal__element",
    value: fields.OIK_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Фонд",
    tooltip: "Номер фонда",
    isLink: true,
    linkRights: [authorities.FUND_LIST],
    className: "info-modal__element",
    value: fields.FUND_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Архив",
    tooltip: "Наименование архива",
    isShowContentTooltip: true,
    isLink: true,
    linkRights: [authorities.ARCHIVE_LIST],
    className: "info-modal__element_title",
    value: fields.ARCHIVE_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Вид",
    tooltip: "Вид дела может принимать следующие значения: Бумажное, Электронное, Гибридное",
    className: "info-modal__element",
    value: fields.STORAGE_KIND,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Объём",
    tooltip: "Объём всех ЭАД, входящих в дело",
    className: "info-modal__element",
    value: fields.CASE_VALUE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Количество листов",
    tooltip: "Количество листов",
    className: "info-modal__element",
    value: fields.NUMBER_OF_PAGES,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Комментарий",
    tooltip: "Поле доступное для редактирования пользователю с соответствующими правами",
    className: "info-modal__element_full-size info-modal__element_textarea",
    value: fields.COMMENT,
    fieldType: "textarea",
    maxLength: "20000",
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
];

export const paperCaseElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Статус",
    tooltip: "Статус",
    className: "info-modal__element_title",
    value: fields.STORAGE_STATUS,
    slotName: "statusSelect",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Примечание к статусу",
    tooltip: "Примечание к статусу",
    placeholder: "Укажите примечание к статусу",
    className: "info-modal__element_title",
    value: fields.STORAGE_STATUS_COMMENT,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    title: "Архив",
    tooltip: "Архив",
    className: "info-modal__element",
    value: fields.STORAGE_ARCHIVE,
  },
  {
    id: uuid(),
    title: "Полный адрес",
    tooltip: "Полный адрес",
    className: "info-modal__element",
    value: fields.STORAGE_ADDRESS,
  },
  {
    id: uuid(),
    title: "Корпус",
    tooltip: "Корпус",
    className: "info-modal__element",
    value: fields.STORAGE_HOUSE,
  },
  {
    id: uuid(),
    title: "Этаж (ярус)",
    tooltip: "Этаж (ярус)",
    className: "info-modal__element",
    value: fields.STORAGE_FLOOR,
  },
  {
    id: uuid(),
    title: "Архивохранилище",
    tooltip: "Архивохранилище",
    className: "info-modal__element",
    value: fields.STORAGE_ARCHIVE_STORAGE,
  },
  {
    id: uuid(),
    title: "Помещение",
    tooltip: "Помещение",
    className: "info-modal__element",
    value: fields.STORAGE_ROOM,
  },
  {
    id: uuid(),
    title: "Стеллаж",
    tooltip: "Стеллаж",
    className: "info-modal__element",
    value: fields.STORAGE_SHELVING,
  },
  {
    id: uuid(),
    title: "Шкаф",
    tooltip: "Шкаф",
    className: "info-modal__element",
    value: fields.STORAGE_CABINET,
  },
  {
    id: uuid(),
    title: "Полка",
    tooltip: "Полка",
    className: "info-modal__element",
    value: fields.STORAGE_SHELF,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Дата передачи",
    tooltip: "Дата передачи",
    className: "info-modal__element",
    value: fields.STORAGE_DATE,
    fieldType: "date",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Примечание к месту хранения",
    tooltip: "Примечание к месту хранения",
    placeholder: "Укажите примечание",
    className: "info-modal__element_title",
    value: fields.STORAGE_COMMENT,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
];

export const techElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, изменивший запись",
    className: "info-modal__element_title",
    value: fields.COMMENT_UPDATE_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата изменения",
    className: "info-modal__element_title",
    value: fields.COMMENT_UPDATE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    className: "info-modal__element_title",
    value: fields.CASE_ID,
  },
];

export const fullModalElements = [...modalElements, ...techElements];

export const inventoryPaperModalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок",
    tooltip: "Заголовок описи дел, с которой связано дело",
    className: "info-modal__element_title",
    isLink: true,
    linkRights: [authorities.INVENTORY_LIST],
    value: fields.INVENTORY_PAPER_TITLE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Номер",
    tooltip: "Номер описи дел в ОИК, с которой связано дело",
    className: "info-modal__element",
    value: fields.INVENTORY_PAPER_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Год",
    tooltip: "Год, за который сформирована опись дел",
    className: "info-modal__element",
    value: fields.INVENTORY_PAPER_YEAR,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Вид",
    tooltip: "Вид описи дел, с которой связано дело может принимать значения: Больше или равно 10 лет, Постоянно, По личному составу",
    className: "info-modal__element_title",
    value: fields.INVENTORY_PAPER_CATEGORY,
    isShowContentTooltip: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип",
    tooltip: "Тип описи дел может принимать значения Передача документов, Изменение сроков хранения документов",
    className: "info-modal__element_title",
    value: fields.INVENTORY_PAPER_KIND,
  },
];

export const inventoryEadModalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок",
    tooltip: "Заголовок описи дел, с которой связано дело",
    className: "info-modal__element_title",
    isLink: true,
    linkRights: [authorities.INVENTORY_LIST],
    value: fields.INVENTORY_EAD_TITLE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Номер",
    tooltip: "Номер описи дел в ОИК, с которой связано дело",
    className: "info-modal__element",
    value: fields.INVENTORY_EAD_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Год",
    tooltip: "Год, за который сформирована опись дел",
    className: "info-modal__element",
    value: fields.INVENTORY_EAD_YEAR,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Вид",
    tooltip: "Вид описи дел, с которой связано дело может принимать значения: Больше или равно 10 лет, Постоянно, По личному составу",
    className: "info-modal__element_title",
    value: fields.INVENTORY_EAD_CATEGORY,
    isShowContentTooltip: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип",
    tooltip: "Тип описи дел может принимать значения Передача документов, Изменение сроков хранения документов",
    className: "info-modal__element_title",
    value: fields.INVENTORY_EAD_KIND,
  },
];
