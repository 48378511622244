import { IInventoryElement } from "@monorepo/inventory/src/views/InventoryView/types/inventoryElement";
import moment from "moment";
import { getFileSize } from "@monorepo/utils/src/utils/getFileSize";

export const convertApiItemToUi = (item: IInventoryElement): any => ({
  ...item,
  number: item.number || "",
  index: item.index?.toString() || "",
  caseYear: item.year || "",
  caseKind: item.kind,
  fundNumber: item.fund?.number,
  archiveName: item.archive?.name || "",
  statusTitle: item.status?.title || "",
  historicalReference: item.historicalReference || "",
  comment: item.comment || "",
  oikName: item.oik?.shortName || "",
  inventoryPaperHeader: item.inventoryPaper?.header || "",
  inventoryPaperNumber: item.inventoryPaper?.number || "",
  inventoryPaperYear: item.inventoryPaper?.year || "",
  inventoryPaperKind: item.inventoryPaper?.kind?.title || "",
  inventoryPaperCategory: item.inventoryPaper?.category?.title || "",
  inventoryEadHeader: item.inventoryEad?.header || "",
  inventoryEadNumber: item.inventoryEad?.number || "",
  inventoryEadYear: item.inventoryEad?.year || "",
  inventoryEadKind: item.inventoryEad?.kind?.title || "",
  inventoryEadCategory: item.inventoryEad?.category?.title || "",
  commentUpdateDate: item.commentUpdateDate ? moment(item.commentUpdateDate).format("YYYY-MM-DD HH:mm") : "",
  commentUpdateUser: item.commentUpdateUser || "",
  totalSize: getFileSize(+item.totalSize),
  type: item.type,
  digitalSignatures:
    (item.digitalSignatures || []).map((item) => ({
      ...item,
      expiredAt: item.expiredAt ? moment(item.expiredAt).format("YYYY-MM-DD HH:mm") : "",
      signTime: item.signTime ? moment(item.signTime).format("YYYY-MM-DD HH:mm") : "",
      timeStamp: item.timeStamp ? moment(item.timeStamp).format("YYYY-MM-DD HH:mm") : "",
    })) || [],
  storageKind: item.storageKind?.title || "",
});
