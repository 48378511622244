import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryView/fields";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: { docsMaxDateFrom?: string; docsMaxDateTo?: string; docsMinDateFrom?: string; docsMinDateTo?: string; status?: string };
  }
): void => {
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK, `fieldFilters.${fields.FILTER_OIK}.code`);
};
