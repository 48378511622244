










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AutoRefreshButton",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon(): string {
      return this.active ? "mdi-table-sync" : "mdi-table-refresh";
    },
    tooltip(): string {
      return this.active ? "Автообновление данных списка каждые 15 секунд" : "Автообновление выключено";
    },
    color(): string {
      return this.active ? "primary" : "#76767A";
    },
  },
});
