















































import { defineComponent } from "@vue/composition-api";
import moment from "moment";

const tableHeaders = [
  { width: 200, defaultWidth: 200, value: "createDate", text: "Дата изменения", tooltip: "Дата изменения" },
  { width: 200, defaultWidth: 200, value: "createUser", text: "Пользователь", tooltip: "Пользователь" },
  {
    width: 220,
    defaultWidth: 220,
    value: "commentChangePlacementAddress",
    text: "Причина изменения информации о деле",
    tooltip: "Причина изменения информации о деле",
  },
  { width: 180, defaultWidth: 180, value: "caseStatus", text: "Статус", tooltip: "Статус" },
  { width: 200, defaultWidth: 200, value: "statusComment", text: "Примечание к статусу", tooltip: "Примечание к статусу" },
  { width: 120, defaultWidth: 120, value: "house", text: "Корпус", tooltip: "Корпус" },
  { width: 120, defaultWidth: 120, value: "floor", text: "Этаж (ярус)", tooltip: "Этаж (ярус)" },
  { width: 180, defaultWidth: 180, value: "archiveStorage", text: "Архивохранилище", tooltip: "Архивохранилище" },
  { width: 160, defaultWidth: 160, value: "room", text: "Помещение", tooltip: "Помещение" },
  { width: 120, defaultWidth: 120, value: "shelving", text: "Стеллаж", tooltip: "Стеллаж" },
  { width: 120, defaultWidth: 120, value: "cabinet", text: "Шкаф", tooltip: "Шкаф" },
  { width: 120, defaultWidth: 120, value: "shelf", text: "Полка", tooltip: "Полка" },
];

export default defineComponent({
  name: "DocChangesHistory",
  props: {
    changesHistory: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tableHeaders,
    };
  },
  computed: {
    resultItems() {
      return this.changesHistory.map((item: any) => {
        const addressObj = (item.addressList || []).reduce((result: Record<string, string>, current: any) => {
          result[current.type?.toLowerCase() || ""] = current.value;
          return result;
        }, {});
        return {
          ...item,
          statusComment: item.statusComment || "",
          caseStatus: item.caseStatus?.title || "",
          house: addressObj.house || "",
          floor: addressObj.floor || "",
          archiveStorage: addressObj.archive_storage || "",
          room: addressObj.room || "",
          shelving: addressObj.shelving || "",
          cabinet: addressObj.cabinet || "",
          shelf: addressObj.shelf || "",
          createDate: item.createDate ? moment(item.createDate).format("YYYY-MM-DD HH:mm:ss") : "",
        };
      });
    },
  },
  methods: {
    onScrollCb(e: Event) {
      const element = document.getElementById("changes-history-table");
      if (element) {
        element.scrollLeft = (e.target as HTMLElement)?.scrollLeft;
      }
    },
  },
});
