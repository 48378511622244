




















































import { defineComponent } from "@vue/composition-api";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";

export default defineComponent({
  name: "TreeStorageItem",
  components: {
    SelectFilter,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    nodeId: {
      type: String,
    },
    sectionName: {
      type: String,
      required: true,
    },
    typesFieldName: {
      type: String,
      default: "types",
    },
  },
  computed: {
    types(): Record<string, unknown>[] {
      return this.$store.getters[`${this.sectionName}/${this.typesFieldName}`];
    },
    archives(): Record<string, unknown>[] {
      return this.$store.getters[`${this.sectionName}/archives`];
    },
  },
});
