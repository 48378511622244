import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryView/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Документы",
    tooltip: "Документов получено/Документов в деле",
    defaultWidth: 180,
    value: fields.INVENTORY_DOCS,
    isLink: false,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    slotName: "inventoryDocs",
  },
  {
    text: "Архивный шифр",
    tooltip: "Архивный шифр дела",
    defaultWidth: 180,
    value: fields.CASE_ARCHIVAL_CIPHER,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Индекс",
    tooltip: "Индекс дела",
    defaultWidth: 150,
    value: fields.CASE_INDEX,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Заголовок",
    tooltip: "Заголовок дела",
    defaultWidth: 200,
    value: fields.CASE_HEADER,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ОИК",
    tooltip: "Краткое наименование ОИК",
    defaultWidth: 180,
    value: fields.OIK_NAME,
    linkRights: [authorities.OIK_LIST],
    isLink: true,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Фонд",
    tooltip: "Номер фонда",
    defaultWidth: 180,
    value: fields.FUND_NUMBER,
    linkRights: [authorities.FUND_LIST],
    isLink: true,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Вид",
    tooltip: "Вид дела может принимать следующие значения: Бумажное, Электронное, Гибридное",
    defaultWidth: 150,
    value: fields.STORAGE_KIND,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip: "Статус",
    defaultWidth: 170,
    value: fields.STATUS_TITLE,
    sortField: "status",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
