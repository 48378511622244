


















































































































































































import { defineComponent, ref } from "@vue/composition-api";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import { mapActions, mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/InventoryView/utils/convertApiItemToUi";
import {
  inventoryPaperModalElements,
  inventoryEadModalElements,
  modalElements,
  techElements,
  paperCaseElements,
} from "@monorepo/utils/src/variables/projectsData/inventoryView/modalElements";
import { get } from "lodash";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoryView/viewTitle";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { IInventoryElement } from "@monorepo/inventory/src/views/InventoryView/types/inventoryElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { IDocument } from "@monorepo/inventory/src/views/EadView/types/IDocument";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryView/fields";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { Route } from "vue-router";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import useFormLinkByAuthoritiesModal from "@monorepo/utils/src/composables/useFormLinkByAuthoritiesModal";
import { convertSaveCardObject } from "@monorepo/inventory/src/views/InventoryView/utils/convertSaveCardObject";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import DigitalSignaturesTable from "@monorepo/inventory/src/views/EadView/components/DigitalSignaturesTable.vue";
import DocChangesHistory from "@monorepo/uikit/src/components/tableCardModal/DocChangesHistory.vue";
import TreeStorageDialog from "./TreeStorageDialog.vue";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { CaseTypes } from "@monorepo/inventory/src/constants/caseTypes";

export default defineComponent({
  name: "InventoryInfoModal",
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    ExportBtn,
    ToggleDocumentView,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    FormInputElement,
    CardModalChapter,
    SelectRolesCard,
    DigitalSignaturesTable,
    DocChangesHistory,
    TreeStorageDialog,
    DocumentTable: () =>
      import(
        /* webpackChunkName: "InventoryViewDocumentsTable" */
        "@monorepo/inventory/src/views/InventoryView/components/DocumentsTable.vue"
      ),
    DocumentTree: () =>
      import(
        /* webpackChunkName: "SystemAccessesUsersTab" */
        "@monorepo/inventory/src/views/InventoryView/components/DocumentTree.vue"
      ),
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      ModalType,
      isSaveLoading: false,
      modalElements,
      techElements,
      paperCaseElements,
      inventoryPaperModalElements,
      inventoryEadModalElements,
      element: {} as ReturnType<typeof convertApiItemToUi>,
      viewUniqKey,
      openedPanels: [0, 1, 2],
      currentDocumentView: 0,
      isShowDocuments: false,
      openedDocument: "",
      section: Sections.INVENTORY,
      cardMode: CardMode,
      documents: {},
      fields,
      documentViews: ["DocumentTable", "DocumentTree"],
      isLoading: false,
      storageNodeId: 20,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("inventoryView", ["data", "openedId", "documentList", "paperStatuses", "archives"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    baseModalElements(): IModalElementType[] {
      return this.isPaper || this.isHybrid
        ? (this.resultModalElements as IModalElementType[])
        : (this.resultModalElements as IModalElementType[]).filter((item) => item.value !== fields.NUMBER_OF_PAGES);
    },
    isRequiredReason(): boolean {
      return ["Изъяты или утрачены", "Снято с учета"].includes((this.formValues as any)[fields.STORAGE_STATUS]);
    },
    isRequiredDate(): boolean {
      return ["В стадии проверки", "Получение подтверждено"].includes((this.formValues as any)[fields.STORAGE_STATUS]);
    },
    resultPaperCaseElements(): IModalElementType[] {
      return paperCaseElements.map((item) => {
        const isRequired =
          (this.isRequiredDate && item.value === fields.STORAGE_DATE) || (this.isRequiredReason && item.value === fields.STORAGE_STATUS_COMMENT);
        if (isRequired) {
          return {
            ...item,
            isRequired: true,
          };
        }
        return item;
      });
    },
    isEditQueuePrestine(): boolean {
      return Object.entries(this.formValues).every(([key, value]) => {
        return value === (this.changedFormValues as any)[key];
      });
    },
    isDisabledSubmit(): boolean {
      return this.isConfirmedStatus && !(this.formValues as any).addressNodeId;
    },
    isConfirmed(): boolean {
      return this.isConfirmedStatus && (this.changedFormValues as any)[fields.STORAGE_STATUS] !== "Получение подтверждено";
    },
    submitTitle(): string {
      return this.isConfirmed ? "Подтвердить факт физического получения" : "Сохранить";
    },
    isShowContentTooltip(): unknown {
      return (key: string) => {
        switch (key) {
          case this.fields.INVENTORY_PAPER_KIND:
          case this.fields.INVENTORY_EAD_KIND:
            return true;
          default:
            return false;
        }
      };
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    isShowEpSection(this: { element: IInventoryElement }): boolean {
      return !!this.element?.digitalSignatures?.length;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.CASE_EXPORT);
    },
    color(): string {
      if (!this.totalDocsCount || !this.docsCount) {
        return "#dfdfe4";
      } else {
        const alphaLayer = this.docsCount / this.totalDocsCount;
        return `rgba(0, 164, 89, ${alphaLayer === 1 ? 1 : alphaLayer < 0.4 ? 0.4 : alphaLayer > 0.7 ? 0.7 : alphaLayer})`;
      }
    },
    secondChipColor(): string {
      if (!this.paperTotalCount || !this.paperReceivedCount) {
        return "#dfdfe4";
      } else {
        const alphaLayer = this.paperReceivedCount / this.paperTotalCount;
        return `rgba(0, 164, 89, ${alphaLayer === 1 ? 1 : alphaLayer < 0.4 ? 0.4 : alphaLayer > 0.7 ? 0.7 : alphaLayer})`;
      }
    },
    totalDocsCount(): number {
      return this.isPaper ? this.paperTotalCount : this.eadTotalCount;
    },
    docsCount(): number {
      return this.isPaper ? this.paperReceivedCount : this.eadReceivedCount;
    },
    eadTotalCount(): number {
      return this.element?.totalEadCount || 0;
    },
    eadReceivedCount(): number {
      return this.element?.receivedEadCount || 0;
    },
    paperReceivedCount(): number {
      return this.element?.receivedPaperCount || 0;
    },
    paperTotalCount(): number {
      return this.element?.totalPaperCount || 0;
    },
    getItemElement(): unknown {
      return (key: string) => {
        switch (key) {
          default:
            return get(this.element, key);
        }
      };
    },
    isHybrid(): boolean {
      return this.element?.storageKind === CaseTypes.HYBRID;
    },
    isPaper(): boolean {
      return this.element?.storageKind === CaseTypes.PAPER;
    },
    isConfirmedStatus(): boolean {
      return (this.formValues as any)[fields.STORAGE_STATUS] === "Получение подтверждено";
    },
    paperSelectStatuses(): { label: string; id: string }[] {
      return this.paperStatuses.map((item: any) => ({
        label: item.title,
        id: item.title,
      }));
    },
    isNeedSaveComment(): boolean {
      return (this.changedFormValues as any).comment !== (this.formValues as any).comment;
    },
    isNeedSaveStorageInfo(): boolean {
      return Object.entries(this.formValues)
        .filter(([key, value]) => !["historicalReference", "comment"].includes(key))
        .some(([key, value]) => {
          return value !== (this.changedFormValues as any)[key];
        });
    },
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          $route: Route;
          addQueryOpenedId: () => void;
          getInventoryElement: (val: string) => Promise<IInventoryElement>;
          element: ReturnType<typeof convertApiItemToUi>;
          getDocumentList: (id: string) => Promise<IDocument>;
          isShowDocuments: boolean;
          data: IInventoryElement[];
          isLoading: boolean;
          isShowAnimation: boolean;
          formValues: Record<string, string | any>;
          changedFormValues: Record<string, string | any>;
          archives: Record<string, unknown>[];
          fields: Record<string, string>;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getInventoryElement(value)
            .then((data: IInventoryElement) => {
              this.getDocumentList(data?.guid || "").then((data) => {
                if (data) {
                  this.isShowDocuments = true;
                }
              });
              this.element = convertApiItemToUi(data || {});
              const addressObj = (this.element.addressList || []).reduce((result: Record<string, string>, current: any) => {
                result[current.type?.toLowerCase() || ""] = current.value;
                return result;
              }, {});
              this.formValues = {
                id: this.element.id,
                historicalReference: this.element.historicalReference,
                comment: this.element.comment || "",
                [fields.STORAGE_STATUS]: this.element.status?.title || "",
                [fields.STORAGE_STATUS_COMMENT]: this.element.statusComment || "",
                [fields.STORAGE_ARCHIVE]: addressObj.archive
                  ? this.archives.find((archive) => (archive.id as string)?.toString() === addressObj.archive.toString())?.shortName || ""
                  : "",
                [fields.STORAGE_ADDRESS]: addressObj.address || "",
                [fields.STORAGE_HOUSE]: addressObj.house || "",
                [fields.STORAGE_FLOOR]: addressObj.floor || "",
                [fields.STORAGE_ARCHIVE_STORAGE]: addressObj.archive_storage || "",
                [fields.STORAGE_ROOM]: addressObj.room || "",
                [fields.STORAGE_SHELVING]: addressObj.shelving || "",
                [fields.STORAGE_CABINET]: addressObj.cabinet || "",
                [fields.STORAGE_SHELF]: addressObj.shelf || "",
                [fields.STORAGE_COMMENT]: this.element.additionalInfoOfPlacementAddress || "",
                [fields.STORAGE_CHANGE_REASON]: this.element.commentChangePlacementAddress || "",
                [fields.STORAGE_DATE]: this.element.dateTransferToStorage || "",
                addressNodeId: this.element.addressNodeId || "",
              };
              this.changedFormValues = { ...this.formValues };
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("inventoryView", ["refreshScroll", "changeOpenedId", "getDocumentList", "getInventoryElement", "saveCard", "savePaperStatus"]),
    toggleDocumentView(view: number) {
      this.currentDocumentView = view;
    },
    openTableView(id: string) {
      this.openedDocument = id;
      this.currentDocumentView = 0;
    },
    changeNodeId({ id, addressList }: { id: string; addressList: any[] }) {
      const addressObj = (addressList || []).reduce((result: Record<string, string>, current: any) => {
        result[current.type?.toLowerCase() || ""] = current.value;
        return result;
      }, {});
      this.formValues = {
        ...this.formValues,
        [fields.STORAGE_ARCHIVE]: addressObj.archive
          ? this.archives.find((archive: Record<string, unknown>) => (archive.id as string)?.toString() === addressObj.archive.toString())
              ?.shortName || ""
          : "",
        [fields.STORAGE_ADDRESS]: addressObj.address || "",
        [fields.STORAGE_HOUSE]: addressObj.house || "",
        [fields.STORAGE_FLOOR]: addressObj.floor || "",
        [fields.STORAGE_ARCHIVE_STORAGE]: addressObj.archive_storage || "",
        [fields.STORAGE_ROOM]: addressObj.room || "",
        [fields.STORAGE_SHELVING]: addressObj.shelving || "",
        [fields.STORAGE_CABINET]: addressObj.cabinet || "",
        [fields.STORAGE_SHELF]: addressObj.shelf || "",
        addressNodeId: id || "",
      };
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    clickElementCb(header: { isLink: boolean; value: string }) {
      switch (header.value) {
        case fields.FUND_NUMBER:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/dictionaries/fund", {
            number: this.element?.fundNumber || "",
            id: this.element?.fund?.id || "",
            isOpenModal: "1",
          });
          break;
        case fields.ARCHIVE_NAME:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/dictionaries/archive", {
            shortName: this.element?.archive?.shortName || "",
            id: this.element?.archive?.id || "",
            isOpenModal: "1",
          });
          break;
        case fields.INVENTORY_EAD_TITLE:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/inventory/case-notes-list", {
            header: this.element?.inventoryEadHeader || "",
            isOpenModal: "1",
          });
          break;
        case fields.INVENTORY_PAPER_TITLE:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/inventory/case-notes-list", {
            header: this.element?.inventoryPaperHeader || "",
            isOpenModal: "1",
          });
          break;
        case fields.OIK_NAME: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/dictionaries/oik", {
            name: this.element?.oik?.name as string,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
    closeModal() {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
    },
    downloadPdf() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    openCardPdf(id: string) {
      if (id) {
        this.openNewWindow(`${getFullPath(QUERY_PATH.GET_INVENTORIES_PDF_CARD)}/${id}/pdf`);
      }
    },
    getResultSaveData(data: Record<string, string>) {
      return {
        historicalReference: data.historicalReference || "",
        comment: data.comment || "",
      };
    },
    getPaperStatusData(data: Record<string, string>) {
      return {
        status: data[fields.STORAGE_STATUS] ? this.paperStatuses.find((status: any) => status.title === data[fields.STORAGE_STATUS])?.code : "",
        statusComment: data[fields.STORAGE_STATUS_COMMENT] || "",
        caseIds: [this.openedId],
        dateTransferToStorage: data[fields.STORAGE_DATE] || "",
        addressNodeId: this.isConfirmedStatus ? data.addressNodeId : (this.changedFormValues as any).addressNodeId,
        additionalInfoOfPlacementAddress: data[fields.STORAGE_COMMENT] || "",
        commentChangePlacementAddress: data[fields.STORAGE_CHANGE_REASON] || "",
      };
    },
    async onSave(data: Record<string, string>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = { ...this.getResultSaveData(data), entityId: this.openedId };
        const resultPaperStatusData = this.getPaperStatusData(data);

        this.isSaveLoading = true;
        const promises = [];
        if (this.isNeedSaveStorageInfo) {
          promises.push(this.savePaperStatus(resultPaperStatusData));
        }
        if (this.isNeedSaveComment) {
          promises.push(await this.saveCard(resultData));
        }
        const isSaved = await Promise.all(promises);
        this.isSaveLoading = false;
        if (isSaved) {
          this.changedFormValues = { ...this.formValues };
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.refreshScroll();
          this.closeModal();
        }
      } else {
        return;
      }
    },
    changeContracts(
      this: {
        $formulate: {
          setValues: (key: string, object: Record<string, string>) => void;
        };
      },
      contracts: Record<string, string>[],
      values: Record<string, string>
    ) {
      (
        this.$formulate as {
          setValues: (key: string, object: Record<string, string>) => void;
        }
      ).setValues("inventory-info-modal", Object.assign(values, { contracts }));
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({});
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "inventoryView", Sections.INVENTORY, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "inventoryView");
    const { resultModalElements, moveByAuthorities } = useFormLinkByAuthoritiesModal(root, modalElements);

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
      resultModalElements,
      moveByAuthorities,
    };
  },
});
