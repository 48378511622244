import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryView/fields";

export const filtersElements = [
  {
    value: fields.FILTER_CASE_HEADER,
    title: "Заголовок",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 250,
  },
  {
    value: fields.FILTER_INDEX,
    title: "Индекс дела",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 250,
  },
  {
    value: fields.FILTER_CHIPER,
    title: "Архивный шифр дела",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 250,
  },
  {
    value: fields.FILTER_OIK,
    title: "ОИК",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getOikAutocompleteItems",
    className: "filters__element-wrap mt-4",
    width: 300,
  },
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "select",
    selectItemsField: "fundNames",
    isShowDeletedIcon: true,
    className: "filters__element-wrap mt-4",
    width: 300,
  },
  {
    value: fields.FILTER_CASE_CARRIER,
    title: "Вид дела",
    tooltip: "Выбор из списка, возможные значения: Электронное, Бумажное, Гибридное",
    type: "select",
    selectItemsField: "carriers",
    className: "filters__element-wrap mt-4",
    width: 354,
  },
  {
    value: fields.FILTER_STATUS,
    title: "Статус",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "select",
    selectItemsField: "statuses",
    className: "filters__element-wrap mt-4",
    width: 300,
  },
];
