





























import { defineComponent } from "@vue/composition-api";
import TreeStorage from "@monorepo/inventory/src/components/TreeStorage.vue";

export default defineComponent({
  components: {
    TreeStorage,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      dialogVisible: false,
      treeOpen: [],
      currentNodeId: "",
      currentAddressList: [] as any[],
    };
  },
  methods: {
    closeModal() {
      this.dialogVisible = false;
    },
    saveSelection() {
      this.$emit("change", { id: this.currentNodeId, addressList: this.currentAddressList });
      this.closeModal();
    },
    showModal() {
      this.currentNodeId = this.value || "";
      this.dialogVisible = true;
    },
    changeTree({ id, addressList }: { id: string; addressList?: any[] }) {
      this.currentNodeId = id;
      this.currentAddressList = addressList || [];
    },
  },
});
