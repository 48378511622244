import { ActionContext, Module } from "vuex";
import { IRootState } from "@monorepo/inventory/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/inventory/src/views/InventoryView/utils/convertFiltersToApi";
import { IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IDocument } from "@monorepo/inventory/src/views/EadView/types/IDocument";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import axios, { AxiosResponse } from "axios";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IResponse } from "@monorepo/utils/src/api/types/response";

interface ITableFiltersObjectResult extends ITableFiltersObject {
  inventoryDocumentsCells: string[];
}

interface ITableState {
  filters: ITableFiltersObjectResult;
  totalLength: number;
  section?: Sections;
  data: IEadElement[];
  infiniteId: string;
  sectionCells: ISection;
  isOpenFilters: boolean;
  autorefresh: boolean;
  inventoryCells: string[];
  cells: string[];
  documentList: IDocument;
  searchTemplates: ISearchTemplate[];
  libraries: {
    oiks: string[];
    funds: string[];
    paperStatuses: Record<string, string>[];
    types: any[];
    archives: any[];
    inventoryKinds: { code: string; name: string }[];
    inventoryCategories: { code: string; name: string }[];
  };
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
}

const defaultFilters = (): ITableFiltersObjectResult => ({
  sort: {
    date: SORT_TYPE.DESC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  inventoryDocumentsCells: [],
  fieldFilters: {},
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_INVENTORIES_CSV_LIST;
    case "XLSX":
      return QUERY_PATH.GET_INVENTORIES_XLS_LIST;
    default:
      return QUERY_PATH.GET_INVENTORIES_PDF_LIST;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    totalLength: 0,
    inventoryCells: [],
    searchTemplates: [],
    isOpenFilters: false,
    autorefresh: false,
    section: Sections.CASE,
    documentList: {} as IDocument,
    sectionCells: {} as ISection,
    data: [],
    cells: [],
    libraries: {
      oiks: [],
      funds: [],
      paperStatuses: [],
      types: [],
      archives: [],
      inventoryKinds: [],
      inventoryCategories: [],
    },
    isTableLoading: false,
    isLoadingToggleFilters: false,
    isLoadingChangeAutorefresh: false,
  }),
  mutations: {
    ...baseMutations,
    addTypesLib(state: ITableState, payload: any[]) {
      state.libraries.types =
        payload.map((type) => {
          return { ...type, value: type.code };
        }) || [];
    },
    addArchivesLib(state: ITableState, payload: any[]) {
      state.libraries.archives =
        payload.map((archive) => {
          return { ...archive, value: archive.id, title: archive.shortName };
        }) || [];
    },
    addInventoryKindsLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.inventoryKinds = payload || [];
    },
    addInventoryCategoriesLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.inventoryCategories = payload || [];
    },
    funds(state: ITableState, payload: string[]) {
      state.libraries.funds = payload;
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    setCells(state: ITableState, payload: string[]) {
      state.inventoryCells = payload;
    },
    setDocumentsTableCells(state: ITableState, payload: string[]) {
      state.filters.inventoryDocumentsCells = payload;
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    setDocumentList(state: ITableState, payload: { data: IDocument }) {
      state.documentList = payload.data;
    },
    addData(
      state: ITableState,
      payload: {
        data: IEadElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    addOiksLib(state: ITableState, payload: string[]) {
      state.libraries.oiks = payload || [];
    },
    addPaperStatusesLib(state: ITableState, payload: Record<string, string>[]) {
      state.libraries.paperStatuses = payload || [];
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    getTypes({ commit, state }) {
      checkExistLibrary(state.libraries.types, async () => {
        const { data } = await getQuery<any[]>(QUERY_PATH.GET_STORAGE_TYPES);
        commit("addTypesLib", data || []);
      });
    },
    async getArchivesData({ commit }) {
      const { data } = await getQuery<any[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_ALL}`);

      if (data !== null) {
        commit("addArchivesLib", data || []);
      }
    },
    async getTreeData(info, params?: { parentNodeId?: string; archiveId?: string }) {
      const { data } = await getQuery<any[]>(`${QUERY_PATH.GET_STORAGE_CATALOG_LIST}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || {} };
      }

      return { data: {} };
    },
    addOpenedId(root, payload: null | string | number) {
      (baseActions as { addOpenedId: (root: ActionContext<ITableState, IRootState>, payload: null | string | number) => void }).addOpenedId(
        root,
        payload
      );
      // if (payload) {
      //   root.commit(
      //     "auth/setUserInventoryHistory",
      //     {
      //       id: uuid(),
      //       href: location.pathname,
      //       filters: root.state.filters,
      //       section: root.state.section,
      //       item: (root.state.data ?? []).find((item: IEadElement) => item.id === payload) || { id: payload },
      //     },
      //     { root: true }
      //   );
      // }
    },
    async getAutocompleteByNumber(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_FUND_LIST + `/by-number/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { number: string }) => ({
          ...item,
          titleValue: item.number,
        }));
      }
      return [];
    },
    async getAutocompleteByName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_ARCHIVE_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    setDocumentsTableCells({ commit }, payload: string[]) {
      commit("setDocumentsTableCells", payload);
    },
    async getOiks({ commit, state }) {
      checkExistLibrary(state.libraries.oiks, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_OIKS_LIB);
        commit("addOiksLib", data);
      });
    },
    async getPaperStatuses({ commit, state }) {
      checkExistLibrary(state.libraries.paperStatuses, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.SAVE_PAPER_STATUS);
        commit("addPaperStatusesLib", data);
      });
    },
    getFunds({ commit, state }) {
      checkExistLibrary(state.libraries.funds, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_FUND_SHORT_ITEM_LIB);
        commit("funds", data || []);
      });
    },
    getInventoryKinds({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.inventoryKinds, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(getFullPath(QUERY_PATH.GET_INVENTORIES_KINDS));
          commit("addInventoryKindsLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка типов описи.");
      }
    },
    getInventoryCategories({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.inventoryCategories, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(
            getFullPath(QUERY_PATH.GET_INVENTORIES_CATEGORIES)
          );
          commit("addInventoryCategoriesLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка видов описи.");
      }
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IEadElement[]>(QUERY_PATH.GET_CASE_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getEadTreeData(info, { length, id }) {
      const params = {
        offset: length,
        limit: 50,
      };

      const { data, total: totalLength } = await getQuery<any[]>(`${QUERY_PATH.GET_EAD_BY_CASE_TREE_LIST}/${id}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    async getInventoryElement(info, payload: string) {
      const { data } = await getQuery<IEadElement>(`${QUERY_PATH.GET_CASE_LIST}${payload}`, { withEad: false });
      return data || {};
    },
    async saveCard(info, data: Record<string, string>): Promise<boolean> {
      try {
        const { entityId, comment } = data;
        await axios.put(getFullPath(QUERY_PATH.SAVE_CASE_COMMENT), { entityId, comment }).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async savePaperStatus(info, data: Record<string, string>): Promise<boolean> {
      try {
        await axios.put(getFullPath(QUERY_PATH.SAVE_PAPER_STATUS), data).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IEadElement[]>(QUERY_PATH.GET_CASE_LIST, { ...params, offset: 0 });

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getDocumentList({ commit }, id) {
      const { data } = await getQuery<IDocument>(`${QUERY_PATH.GET_CASE_LIST}${id}/sections`);
      if (data !== null) {
        commit("setDocumentList", { data: data || {} });
        return { data: data || {} };
      }
      return { data: null };
    },
    async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      if (payload !== "PDF") {
        dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), { root: true });
      } else {
        commit("app/setIsExportFileLoading", true, {
          root: true,
        });
        const { data } = await getQuery<void>(QUERY_PATH.GET_INVENTORIES_PDF_LIST_ASYNC, params);
        if (!data) {
          commit("app/setIsExportFileLoading", false, {
            root: true,
          });
        }
      }
    },
  },
  getters: {
    ...baseGetters,
    types(state: ITableState) {
      return state.libraries.types || [];
    },
    archives(state: ITableState) {
      return state.libraries.archives || [];
    },
    inventoryKinds(state: ITableState) {
      return state.libraries.inventoryKinds || [];
    },
    inventoryCategories(state: ITableState) {
      return state.libraries.inventoryCategories || [];
    },
    cells(state: ITableState) {
      return state.inventoryCells ?? [];
    },
    documentsTableCells(state: ITableState) {
      return state.filters?.inventoryDocumentsCells ?? [];
    },
    documentList(state: ITableState) {
      return state.documentList ?? {};
    },
    oiksLibrary(state: ITableState) {
      return state.libraries.oiks;
    },
    paperStatuses(state: ITableState) {
      return state.libraries.paperStatuses;
    },
    funds(state: ITableState) {
      return state.libraries.funds || [];
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
