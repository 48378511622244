

































import Vue from "vue";
import { defineComponent } from "@vue/composition-api";
import TreeStorageItem from "./TreeStorageItem.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import { useDynamicTree } from "@monorepo/utils/src/composables/useDynamicTree";

export default defineComponent({
  name: "TreeStorage",
  components: {
    TreeStorageItem,
    InfiniteScroll,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    sectionName: {
      type: String,
      required: true,
    },
    typesFieldName: {
      type: String,
    },
  },
  data() {
    return {
      open: [],
      active: [],
      infiniteId: new Date().toString(),
      treeItemsObj: {} as Record<string, any>,
      selectedIds: {} as Record<string, boolean>,
    };
  },
  computed: {
    treeItems(): Record<string, unknown>[] {
      return (this.treeItemsObj.main || []).map(this.getTreeItems);
    },
    itemsIdsObj(): Record<string, unknown>[] {
      return Object.values(this.treeItemsObj).reduce((result: Record<string, any>, current: any[]) => {
        current.forEach((item) => {
          result[item.id] = item;
        });
        return result;
      }, {});
    },
  },
  methods: {
    getTreeData(payload?: { parentNodeId: string }) {
      return this.$store.dispatch(`${this.sectionName}/getTreeData`, payload);
    },
    getAddressList(item: any) {
      let result = [item];
      while (result[0].parentId) {
        result.unshift(this.itemsIdsObj[result[0].parentId]);
      }
      return result;
    },
    onChange(item: any, value: boolean) {
      const values = value ? { id: item.id || "", addressList: this.getAddressList(item) } : { id: "" };
      this.$emit("change", values);
    },
    getTreeItems(node: any) {
      return {
        ...node,
        ...(node.id && node.type !== "SHELF" ? { children: (this.treeItemsObj[node.id] || []).map(this.getTreeItems) } : {}),
      };
    },
    async fetchChildren(item: { children: unknown[]; id: string | number; type: string }) {
      this.openedItems = [...this.openedItems, item];
      if ((this.treeItemsObj[item.id || "main"] || []).length) {
        return;
      }
      const children = await this.fetchTreeChildren(item);
      Vue.set(this.treeItemsObj, item.id || "main", children);
    },
    handleTreeData() {
      return this.getTreeData().then(({ data }) => {
        const resultMainItems = (data || []).map((item: any) => ({ ...item, children: [] }));
        Vue.set(this.treeItemsObj, "main", resultMainItems);
        return { data: this.treeItems };
      });
    },
  },
  setup(props, { root }) {
    const fetchTreeChildren = async (item: { children: unknown[]; id: string | number; type: string }) => {
      const { data } = await root.$store.dispatch(`${props.sectionName}/getTreeData`, {
        parentNodeId: item.id,
      });

      const resultChildren = data.map((treeItem: Record<string, unknown>) => {
        return {
          ...treeItem,
          parentId: item.id,
          ...(treeItem.type === "SHELF"
            ? {}
            : {
                children: [],
              }),
        };
      });
      item.children = resultChildren;
      return resultChildren;
    };

    const handleLoad = (item: { children: unknown[]; id: string | number; type: string; totalLength: number }) => {
      if (item.children.length) {
        return;
      }
      fetchTreeChildren(item);
    };

    const { isLoadingOpenedItems, openedItems } = useDynamicTree({ handleLoad });

    return {
      isLoadingOpenedItems,
      openedItems,
      fetchTreeChildren,
    };
  },
});
