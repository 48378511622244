





























































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import InventoryInfoModal from "@monorepo/inventory/src/views/InventoryView/components/InventoryInfoModal.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/inventoryView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoryView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as InventoryView } from "@monorepo/inventory/src/store/modules/inventoryView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import useGetTableLibs from "./composables/useGetTableLibs";
import { mapActions, mapGetters, mapMutations, Module } from "vuex";
import { breadcrumbs } from "@monorepo/inventory/src/views/InventoryView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/InventoryView/utils/convertApiItemToUi";
import InventoryStatus from "@monorepo/uikit/src/components/tableViews/InventoryCountItemsStatus.vue";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryView/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/inventoryView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/inventory/src/views/InventoryView/utils/defaultCurrentFilters";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";
import { CaseTypes } from "@monorepo/inventory/src/constants/caseTypes";

const SECTION_NAME = "inventoryView";

export default defineComponent({
  name: "InventoryView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    InventoryInfoModal,
    AutoRefreshButton,
    Footer,
    ToggleDocumentView,
    ExportFooterButton,
    InventoryStatus,
    CasesTree: () =>
      import(
        /* webpackChunkName: "casesTree" */
        "@monorepo/inventory/src/views/InventoryView/components/CasesTree.vue"
      ),
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      tableHeaders,
      showEadModal: false,
      currentDocumentView: 0,
      pluralFormTitles: ["Дело", "Дела", "Дел"],
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      CaseTypes,
      autocompleteFiltersKeys: [fields.FILTER_OIK],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "openedId",
      "isSelectAll",
      "isOpenFilters",
      "infiniteId",
      "data",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "funds",
      "paperStatuses",
    ]),
    carriers(): { title: string; value: string }[] {
      return convertToSelectItems(["Бумажное", "Электронное", "Гибридное"]);
    },
    fundNames(): { title: string; value: string; isDeleted: boolean }[] {
      return (this.funds || []).map((fund: { name: string; number: string; isDeleted: boolean }) => ({
        title: fund.name,
        value: fund.number,
        isDeleted: fund.isDeleted,
      }));
    },
    statuses(): { title: string; value: string }[] {
      return convertToSelectItems(this.paperStatuses, "title", "code");
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        carriers: this.carriers,
        fundNames: this.fundNames,
        statuses: this.statuses,
      };
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.CASE_EXPORT);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && this.isShowExport;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    additionalClassObj() {
      return (index: number, event: Record<string, unknown>) => {
        return {
          "monitoring-table__td_first": !index,
          "monitoring-table__td_success": !index && event.totalEadCount && event.totalEadCount === event.receivedEadCount,
        };
      };
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters", "setAutorefresh"]),
    ...mapMutations(SECTION_NAME, ["refreshData", "setSelectedIds"]),
    toggleDocumentView(view: number) {
      this.currentDocumentView = view;
      this.refreshData();
      this.setSelectedIds({});
    },
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        this.addOpenedId(this.data[0].id);
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          this.$store.dispatch("inventoryView/addOpenedId", event.id);
          this.toggleModal(true);
          break;
        case fields.FUND_NUMBER: {
          this.moveByAuthorities(header, "/dictionaries/fund", {
            number: event[fields.FUND_NUMBER] as string,
            id: (event.fund as { id: string })?.id,
            isOpenModal: "1",
          });
          break;
        }
        case fields.OIK_NAME: {
          this.moveByAuthorities(header, "/dictionaries/oik", {
            name: event[fields.OIK_NAME] as string,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const moveByAuthorities = useMoveByAuthorities(root);

    useInitTableStoreModule(root, SECTION_NAME, InventoryView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders, true).then(() => {
      useRefreshTable(root, SECTION_NAME);
    });

    useSetStartFilters(root, SECTION_NAME, ["id", "index", "header"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
    });
    useGetTableLibs(root);
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
      moveByAuthorities,
    };
  },
});
