import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;

  onBeforeMount(() => {
    store.dispatch("inventoryView/getFunds");
    store.dispatch("inventoryView/getPaperStatuses");
    store.dispatch("inventoryView/getTypes");
    store.dispatch("inventoryView/getArchivesData");
  });
};

export default useGetTableLibs;
