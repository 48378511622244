import { ComponentInstance, computed, ComputedRef } from "@vue/composition-api";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";

interface IUseFormLink {
  resultModalElements: ComputedRef<IModalElementType[]>;
  moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, string>) => undefined | void;
  formResultLinks: (modalElements: IModalElementType[]) => IModalElementType[];
}

const useFormLinkByAuthoritiesModal = (root: ComponentInstance, modalElements: unknown[]): IUseFormLink => {
  const store = root.$store;

  const router = root.$router || {};

  const user = computed(() => store.getters["auth/user"]);

  const authoritiesObj = computed(() => {
    return user.value?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
      result[item.authority || ""] = true;
      return result;
    }, {});
  });

  const resultModalElements = computed(() => {
    return formResultLinks(modalElements as IModalElementType[]);
  });

  const moveByAuthorities = (header: { isLink: boolean }, path: string, query: Record<string, string | (string | null)[] | null | undefined>) => {
    if (!header.isLink) {
      return;
    }
    router.push({
      path,
      query,
    });
  };

  const formResultLinks = (modalElements: IModalElementType[]) => {
    return modalElements.map((element) => {
      if (!element.linkRights?.length) {
        return element;
      }
      return {
        ...element,
        isLink: element.isLink ? element.linkRights.some((key) => authoritiesObj.value[key]) : false,
      };
    });
  };

  return {
    resultModalElements,
    moveByAuthorities,
    formResultLinks,
  };
};

export default useFormLinkByAuthoritiesModal;
