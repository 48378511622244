import { ComponentInstance, computed, onBeforeUnmount, watch } from "@vue/composition-api";

const useRefreshTable = (
  root: ComponentInstance,
  moduleName: string,
  time = 15000,
  dataName = "data",
  refreshEventName = "refreshEventList",
  openedIdName = "openedId"
): void => {
  let timer: number | null = null;
  const store = root.$store;
  const data = computed(() => store.getters[`${moduleName}/${dataName}`]);
  const openedId = computed(() => store.getters[`${moduleName}/${openedIdName}`]);
  const isNeedRefresh = computed(() => store.getters[`${moduleName}/autorefresh`]);
  const initMessagesLength = computed(() => store.getters[`${moduleName}/initMessagesLength`]);

  const startRefresh = () => {
    clearTimeout(timer as number);
    timer = window.setTimeout(async () => {
      try {
        await store.dispatch(`${moduleName}/${refreshEventName}`);
        startRefresh();
      } catch (e) {
        stopRefresh();
      }
    }, time);
  };
  const stopRefresh = () => {
    clearTimeout(timer as number);
    timer = null;
  };
  const changeIsNeedRefresh = (newValue: boolean) => {
    if (newValue) {
      timer === null && startRefresh();
    } else {
      stopRefresh();
    }
  };

  onBeforeUnmount(() => {
    stopRefresh();
  });

  watch(isNeedRefresh, changeIsNeedRefresh, { immediate: true });

  watch(data, (newValue) => {
    if (store.getters[`${moduleName}/autorefresh`]) {
      store.commit(`${moduleName}/setAutorefresh`, newValue?.length <= initMessagesLength.value);
    }
  });

  watch(openedId, (newValue) => {
    if (store.getters[`${moduleName}/autorefresh`]) {
      changeIsNeedRefresh(!newValue);
    }
  });
};

export default useRefreshTable;
