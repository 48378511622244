import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.FILTER_CASE_HEADER]: "",
  [fields.FILTER_INDEX]: "",
  [fields.FILTER_CHIPER]: "",
  [fields.FILTER_OIK]: {},
  [fields.FILTER_FUND]: {},
  [fields.FILTER_CASE_CARRIER]: [],
});
