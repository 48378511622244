export interface IInventoryFields {
  CASE_ID: string;
  CASE_HEADER: string;
  CASE_NUMBER: string;
  FUND_NUMBER: string;
  CASE_CARRIER: string;
  CASE_ARCHIVAL_CIPHER: string;
  CASE_SERIAL_NUMBER: string;
  CASE_DOCS_MAX_DATE: string;
  CASE_DOCS_MAX_DATE_FROM: string;
  CASE_DOCS_MAX_DATE_TO: string;
  CASE_DOCS_MIN_DATE: string;
  CASE_DOCS_MIN_DATE_FROM: string;
  CASE_DOCS_MIN_DATE_TO: string;
  CASE_FILES_SIZE: string;
  NOMENCLATURE_NUMBER: string;
  NOMENCLATURE_YEAR: string;
  INVENTORY_PAPER_YEAR: string;
  INVENTORY_PAPER_KIND: string;
  INVENTORY_PAPER_CATEGORY: string;
  INVENTORY_PAPER_TITLE: string;
  INVENTORY_PAPER_NUMBER: string;
  INVENTORY_EAD_YEAR: string;
  INVENTORY_EAD_KIND: string;
  INVENTORY_EAD_CATEGORY: string;
  INVENTORY_EAD_TITLE: string;
  INVENTORY_EAD_NUMBER: string;
  INVENTORY_CHAPTER: string;
  HISTORICAL_REFERENCE: string;
  COMMENT: string;
  FILTER_CASE_HEADER: string;
  FILTER_INDEX: string;
  FILTER_CHIPER: string;
  FILTER_OIK: string;
  FILTER_FUND: string;
  FILTER_CASE_CARRIER: string;
  FILTER_STATUS: string;
  INVENTORY_DOCS: string;
  OIK_NAME: string;
  CASE_INDEX: string;
  CASE_TYPE: string;
  STORAGE_KIND: string;
  CASE_VALUE: string;
  COMMENT_UPDATE_USER: string;
  COMMENT_UPDATE_DATE: string;
  ARCHIVE_NAME: string;
  NUMBER_OF_PAGES: string;
  STATUS_TITLE: string;

  STORAGE_STATUS: string;
  STORAGE_STATUS_COMMENT: string;
  STORAGE_ARCHIVE: string;
  STORAGE_ADDRESS: string;
  STORAGE_HOUSE: string;
  STORAGE_FLOOR: string;
  STORAGE_ARCHIVE_STORAGE: string;
  STORAGE_ROOM: string;
  STORAGE_SHELVING: string;
  STORAGE_CABINET: string;
  STORAGE_SHELF: string;
  STORAGE_COMMENT: string;
  STORAGE_DATE: string;
  STORAGE_CHANGE_REASON: string;
}

export const fields: IInventoryFields = {
  CASE_ID: "id",
  CASE_HEADER: "header",
  CASE_NUMBER: "caseNumber",
  CASE_SERIAL_NUMBER: "number",
  FUND_NUMBER: "fundNumber",
  CASE_CARRIER: "caseCarrier",
  CASE_ARCHIVAL_CIPHER: "archiveCipher",
  CASE_DOCS_MAX_DATE: "docsMaxDate",
  CASE_DOCS_MAX_DATE_FROM: "docsMaxDateFrom",
  CASE_DOCS_MAX_DATE_TO: "docsMaxDateTo",
  CASE_DOCS_MIN_DATE: "docsMinDate",
  CASE_DOCS_MIN_DATE_FROM: "docsMinDateFrom",
  CASE_DOCS_MIN_DATE_TO: "docsMinDateTo",
  CASE_FILES_SIZE: "filesSize",
  NOMENCLATURE_NUMBER: "nomenclatureNumber",
  NOMENCLATURE_YEAR: "nomenclatureYear",
  INVENTORY_PAPER_YEAR: "inventoryPaperYear",
  INVENTORY_PAPER_KIND: "inventoryPaperKind",
  INVENTORY_PAPER_CATEGORY: "inventoryPaperCategory",
  INVENTORY_PAPER_TITLE: "inventoryPaperHeader",
  INVENTORY_PAPER_NUMBER: "inventoryPaperNumber",
  INVENTORY_EAD_YEAR: "inventoryEadYear",
  INVENTORY_EAD_KIND: "inventoryEadKind",
  INVENTORY_EAD_CATEGORY: "inventoryEadCategory",
  INVENTORY_EAD_TITLE: "inventoryEadHeader",
  INVENTORY_EAD_NUMBER: "inventoryEadNumber",
  INVENTORY_CHAPTER: "inventoryChapter",
  HISTORICAL_REFERENCE: "historicalReference",
  INVENTORY_DOCS: "inventoryDocs",
  FILTER_CASE_HEADER: "header",
  FILTER_INDEX: "index",
  FILTER_CHIPER: "archiveCipher",
  FILTER_OIK: "oikCode",
  FILTER_FUND: "fundNumber",
  FILTER_CASE_CARRIER: "caseCarrier",
  FILTER_STATUS: "status",
  COMMENT: "comment",
  OIK_NAME: "oikName",
  CASE_INDEX: "index",
  CASE_TYPE: "type",
  STORAGE_KIND: "storageKind",
  CASE_VALUE: "totalSize",
  COMMENT_UPDATE_USER: "commentUpdateUser",
  COMMENT_UPDATE_DATE: "commentUpdateDate",
  ARCHIVE_NAME: "archiveName",
  NUMBER_OF_PAGES: "numberOfPages",
  STATUS_TITLE: "statusTitle",

  STORAGE_STATUS: "storageStatus",
  STORAGE_STATUS_COMMENT: "statusComment",
  STORAGE_ARCHIVE: "storageArchive",
  STORAGE_ADDRESS: "address",
  STORAGE_HOUSE: "house",
  STORAGE_FLOOR: "floor",
  STORAGE_ARCHIVE_STORAGE: "archiveStorage",
  STORAGE_ROOM: "room",
  STORAGE_SHELVING: "shelving",
  STORAGE_CABINET: "cabinet",
  STORAGE_SHELF: "shelf",
  STORAGE_COMMENT: "additionalInfoOfPlacementAddress",
  STORAGE_DATE: "dateTransferToStorage",
  STORAGE_CHANGE_REASON: "commentChangePlacementAddress",
};
